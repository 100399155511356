import React, { Component } from "react"
import Layout from "../../components/layout/Layout"
import PageHelmet from "../../components/Helmet"
import BGImage from "gatsby-background-image"
import Img from "gatsby-image"

class ValuesPage extends Component {
    render() {
        return(
            <>
                <PageHelmet pageTitle='Core Values' />
                <Layout>
                    {/* Start Banner Area */}
                    <BGImage
                        fluid={this.props.data.headerBackgroundImage.childImageSharp.fluid}>
                        <div className="rn-page-title-area pt--120 pb--190"  data-black-overlay="8">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="rn-page-title text-center pt--100">
                                            <h2 className="title theme-gradient">ABOUT US</h2>
                                            {/* <p>Roninworks is Your One-Stop-Shop</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </BGImage>
                    {/* End Banner Area */}

                    {/* Start About Area  */}
                    <div className="rn-about-area ptb--120 bg_color--1">
                        <div className="rn-about-wrapper">
                            <div className="container">
                                <div className="row row--35 align-items-center">
                                    <div className="col-lg-5">
                                        <div className="thumbnail">
                                        <Img className="w-100" fluid={this.props.data.bodyImage.childImageSharp.fluid} alt="Picture of a Japanese Samurai in the Snow" />
                                        </div>
                                    </div>
                                    <div className="col-lg-7">
                                        <div className="about-inner inner">
                                            <div className="section-title">
                                                <h2 className="title">Our Origin Story</h2>
                                                <p className="description">Ronin was the name given to a samurai without a master during the Japan's feudal period. These warriors were highly skilled, but after the fall of their master they struck out on their own.</p>
                                                <p>While it's true that we may have watched one too many samurai movies, we find something fascinating about the mythos surrounding the highly skilled, but masterless warrior. Our team is made up of professionals who have cut their teeth working for some of the largest companies in the world.</p>
                                                <p>The term Ronin has also become an idiomatic expression for a "wanderer." Perfect for a company that values the flexibility of being able to work from any location around the world.</p>
                                            </div>
                                            {/* <div className="row mt--30">
                                                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                    <div className="about-us-list">
                                                        <h3 className="title">Who we are</h3>
                                                        <p>There are many vtions of passages of Lorem Ipsum available, but the majority have suffered.</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                    <div className="about-us-list">
                                                        <h3 className="title">Who we are</h3>
                                                        <p>There are many vtions of passages of Lorem Ipsum available, but the majority have suffered.</p>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End About Area  */}

                    {/* Start CounterUp Area */}
                    {/* <div className="rn-counterup-area pb--120 bg_color--1">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center">
                                        <h3 className="fontWeight500">Our Fun Facts</h3>
                                    </div>
                                </div>
                            </div>
                            <CounterOne />
                        </div>
                    </div> */}
                    {/* End CounterUp Area */}

                    {/* Start Finding Us Area  */}
                    {/* <div className="rn-finding-us-area rn-finding-us bg_color--1">
                        <div className="inner">
                            <div className="content-wrapper">
                                <div className="content">
                                    <h4 className="theme-gradient">Find Your Work Now</h4>
                                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that.</p>
                                    <a className="rn-btn btn-white" href="/about">Get Started</a>
                                </div>
                            </div>
                            <div className="thumbnail">
                                <div className="image">
                                    <img src="/assets/images/about/finding-us-01.png" alt="Finding Images"/>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* End Finding Us Area  */}

                    {/* Start Team Area  */}
                    {/* <div className="rn-team-area bg_color--1 ptb--120">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title service-style--3 text-center mb--25">
                                        <h2 className="title">Skilled Team</h2>
                                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                
                                {/* Start Single Team  */}
                                {/* <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                    <div className="team">
                                        <div className="thumbnail">
                                            <img className="w-100" src="/assets/images/team/team-01.jpg" alt="Blog Images"/>
                                        </div>
                                        <div className="content">
                                            <h4 className="title">Jone Due</h4>
                                            <p className="designation">Sr. Web Developer</p>
                                        </div>
                                        <ul className="social-icon" >
                                            <li><a href="https://www.facebook.com/"><FaFacebookF /></a></li>
                                            <li><a href="http://linkedin.com/"><FaLinkedinIn /></a></li>
                                            <li><a href="https://twitter.com/"><FaTwitter /></a></li>
                                        </ul>
                                    </div>
                                </div> */}
                                {/* End Single Team  */}

                                {/* Start Single Team  */}
                                {/* <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                    <div className="team">
                                        <div className="thumbnail">
                                            <img className="w-100" src="/assets/images/team/team-02.jpg" alt="Blog Images"/>
                                        </div>
                                        <div className="content">
                                            <h4 className="title">BM. Alamin</h4>
                                            <p className="designation">Sr. Web Developer</p>
                                        </div>
                                        <ul className="social-icon" >
                                            <li><a href="https://www.facebook.com/"><FaFacebookF /></a></li>
                                            <li><a href="http://linkedin.com/"><FaLinkedinIn /></a></li>
                                            <li><a href="https://twitter.com/"><FaTwitter /></a></li>
                                        </ul>
                                    </div>
                                </div> */}
                                {/* End Single Team  */}

                                {/* Start Single Team  */}
                                {/* <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                    <div className="team">
                                        <div className="thumbnail">
                                            <img className="w-100" src="/assets/images/team/team-03.jpg" alt="Blog Images"/>
                                        </div>
                                        <div className="content">
                                            <h4 className="title">Jone Due</h4>
                                            <p className="designation">Sr. Web Developer</p>
                                        </div>
                                        <ul className="social-icon" >
                                            <li><a href="https://www.facebook.com/"><FaFacebookF /></a></li>
                                            <li><a href="http://linkedin.com/"><FaLinkedinIn /></a></li>
                                            <li><a href="https://twitter.com/"><FaTwitter /></a></li>
                                        </ul>
                                    </div>
                                </div> */}
                                {/* End Single Team  */}

                            {/* </div>
                        </div>
                    </div>  */}
                    {/* End Team Area  */}

                    {/* Start Testimonial Area */}
                    {/* <div className="rn-testimonial-area bg_color--5 ptb--120">
                        <div className="container">
                            <Testimonial />
                        </div>
                    </div> */}
                    {/* End Testimonial Area */}

                </Layout>
            </>
        )
    }
}

export default ValuesPage

export const query = graphql`
    query {
        headerBackgroundImage: file(relativePath: { eq: "services-strategy-banner.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        bodyImage: file(relativePath: { eq: "about-us.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }        
    }
`